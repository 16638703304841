import React from "react";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const styles = {
      container: {
        height: this.state.open ? "171px" : 0,
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        background: "white",
        opacity: 1,
        color: "#fafafa",
        transition: "height 0.2s ease",
        zIndex: 2
      },
      menuList: {
        // paddingTop: "1rem"
      }
    };
    return (
      <div style={styles.container} className="container mx-auto">
        {this.state.open ? (
          <div style={styles.menuList}>{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}

export default Menu;
