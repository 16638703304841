import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import MenuItem from "./MenuItem";
import biologo from "../../images/bio_logo.svg";
import "./Header.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  const menu = [
    { name: "Home", link: "/" },
    {
      name: "BioMonitor III",
      link: "/bm3"
    },
    {
      name: ["MoMe", <sup>&reg;</sup>, " Kardia"],
      link: "/mome"
    }
  ];
  const menuItems = menu.map((val, index) => {
    return (
      <Link to={val.link} key={index} activeClassName="active">
        <MenuItem
          delay={`${index * 0.1}s`}
          onClick={() => {
            handleLinkClick();
          }}
        >
          {val.name}
        </MenuItem>
      </Link>
    );
  });

  const navigation = menu.map((val, index) => {
    return (
      <Link
        className={`block text-sm mt-4 md:inline-block md:mt-0 no-underline text-blue-900 list-end h-12 md:flex md:items-center`}
        to={val.link}
        key={index}
        onClick={() => {
          handleLinkClick();
        }}
        activeClassName="active"
      >
        <div className="md:border-r md:px-3">{val.name}</div>
      </Link>
    );
  });

  return (
    <div>
      <div className="container mx-auto relative flex justify-between bg-white w-full text-white my-0 p-3 top-0 left-0 z-50 ">
        <Link to="/">
          <img
            src={biologo}
            className="w-48 md:w-64"
            alt="BIOTRONIK Logo"
          ></img>
        </Link>
      </div>
    </div>
  );
};

export default Header;
