import React from "react";
import MktoFormFooter from "../components/Forms/MktoFormFooter";
import biologo from "../images/bio_logo_white.svg";

const Footer = () => {
  return (
    <footer className="bg-bioBlue">
      <div className="container mx-auto p-4 w-full">
        <img
          src={biologo}
          className="w-48 md:w-48 mx-auto"
          alt="BIOTRONIK Logo"
        ></img>
        <div className="text-center text-white mt-6 text-xs">
          6024 Jean Road
        </div>
        <div className="text-center text-white text-xs">
          Lake Oswego, OR 97035
        </div>
        <div className="text-center text-white text-xs mt-6">
          {new Date().getFullYear()} BIOTRONIK, Inc. | All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
